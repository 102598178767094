

























import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { defineComponent, PropType, Ref, ref } from "@vue/composition-api";
import { ApiGetEmployeeContractLineDto, ApiUpsertEmployeeContractLineDto } from "@/api/generated/Api";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { api } from "@/api/api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";

export default defineComponent({
  name: "EditHoursModal",
  components: { BaseModalForm },
  emits: ["refresh"],
  props: {
    data: {
      type: Object as PropType<ApiGetEmployeeContractLineDto>,
      required: true,
    },
  },
  setup({ data }, context) {
    const modalConfig = {
      headline: "Endre undervisningstimer",
      type: ModalType.Edit,
    };

    const formValues: Ref<ApiUpsertEmployeeContractLineDto> = ref({
      description: data.description,
      amountOfHours: data.amountOfHours,
    });

    const onSubmit = async () => {
      if (getValidatableRef(context.refs.form)?.validate()) {
        await globalLoadingWrapper({ blocking: true }, async () => {
          await api.course.updateEmployeeContractLine(data.id, formValues.value);
          context.emit("updated");
        });
      }
    };

    return { onSubmit, formValues, modalConfig, validateNotEmpty };
  },
});
