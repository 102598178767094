import { render, staticRenderFns } from "./ViewResourcePage.vue?vue&type=template&id=5921609c&scoped=true&"
import script from "./ViewResourcePage.vue?vue&type=script&lang=ts&"
export * from "./ViewResourcePage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5921609c",
  null
  
)

export default component.exports