















































import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { useRoute } from "@/shared/useHelpers";
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";
import { ApiGetCurrentEmployeeDto, ApiGetEmployeeContractLineDto } from "@/api/generated/Api";
import { api } from "@/api/api";
import BaseTableSimple from "@/components/shared/table/BaseTableSimple.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import BaseModal from "@/components/shared/BaseModal.vue";
import EditEmployeeHoursModal from "@/components/course/resources/EditEmployeeHoursModal.vue";

export default defineComponent({
  name: "ViewEmployee",
  components: {
    BaseSheet,
    BaseSheetHeader,
    BaseSheetField,
    BaseTableSimple,
    BaseModal,
    EditEmployeeHoursModal,
  },
  setup() {
    const route = useRoute();

    const userId: ComputedRef<number> = computed(() => parseInt(route.params.resourceId));
    const courseId: ComputedRef<number> = computed(() => parseInt(route.params.id));
    const showModal: Ref<boolean> = ref(false);
    const selectedTableRow: Ref<ApiGetEmployeeContractLineDto | null> = ref(null);

    const employee: Ref<ApiGetCurrentEmployeeDto | null> = ref(null);
    const employeeContractLines: Ref<ApiGetEmployeeContractLineDto[] | null> = ref(null);

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        employee.value = (await api.user.getEmployeeAsync(userId.value)).data;
        await getSetEmployeeContractLines();
      });
    });

    const openModal = (item: ApiGetEmployeeContractLineDto) => {
      selectedTableRow.value = item;
      showModal.value = true;
    };

    const getSetEmployeeContractLines = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        employeeContractLines.value = (await api.course.getEmployeeContractLines(courseId.value, userId.value)).data;
      });
    };

    const closeModal = () => (showModal.value = false);

    const refresh = () => {
      closeModal();
      getSetEmployeeContractLines();
    };

    const headers = [
      {
        text: "Timer",
        value: "amountOfHours",
      },
      {
        text: "Beskrivelse",
        value: "description",
      },
      {
        text: "Kosttype",
        value: "costTypeName",
      },
      {
        text: "Kostgruppe",
        value: "costGroup",
      },
      {
        text: "Handlinger",
        value: "actions",
      },
    ];
    return { employee, employeeContractLines, headers, selectedTableRow, refresh, showModal, openModal, closeModal };
  },
});
