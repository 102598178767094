






import { computed, defineComponent } from "@vue/composition-api";
import ViewCustomer from "@/components/course/resources/ViewCustomer.vue";
import ViewEmployee from "@/components/course/resources/ViewEmployee.vue";
import { useRoute } from "@/shared/useHelpers";
import { CourseParticipantType } from "@/shared/enums/courseParticipantType.enum";

export default defineComponent({
  name: "ViewResourcePage",
  components: {
    ViewCustomer,
    ViewEmployee,
  },

  setup() {
    const router = useRoute();
    const whatToShow = computed(() => router.query.type);
    return {
      whatToShow,
      CourseParticipantType,
    };
  },
});
