export const getStatusText = (status: string) =>
  ({
    not_sent: "Ikke sendt",
    sent_but_not_signed: "Sendt, ikke signert",
    signed: "Signert",
  }[status] || "Ukjent status");

export const getUserRole = (userType: string) =>
  ({
    C: "Customer",
    E: "Employee",
  }[userType] || "Ukjent type");
